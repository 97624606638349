/*=================Scss Indexing=============
1.variables
2.typography
3.spacing
4.reset
5.forms
6.mixins
7.shortcode
8.animations
9.text-animation
10.sal
11.header
12.mobile-menu
13.button
14.nav
15.banner
16.swiper
17.funfacts
18.cta
19.about
20.common
21.service
22.projects
23.working-process
24.blog
25.blog-details
26.footer
27.search-input
28./side-bar
29.team
30.testimonials
31.faq
32.pricing
33.date-picker
34.time-picker
35.appoinment
36.awesome-feedback
37.contact
38.pre-loader.scss
39.back-to-top
40. Print Css



==============================================  */


/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';

/* elements */
@import'elements/about';
@import'elements/header';
@import'elements/dropdown';
@import'elements/common';
@import'elements/backtotop';
@import'elements/banner';
@import'elements/category';
@import'elements/button';
@import'elements/shopping-card';
@import'elements/blog';
@import'elements/footer';
@import'elements/tranding-product';
@import'elements/feature-product';
@import'elements/service';
@import'elements/tooltip';
@import'elements/offer-area';
@import'elements/social';
@import'elements/easy-process';
@import'elements/modal-popup';
@import'elements/best-deals';
@import'elements/counter';
@import'elements/team';
@import'elements/testimonials';
@import'elements/store';
@import'elements/navigator';
@import'elements/filter';
@import'elements/nice-select';
@import'elements/contact';
@import'elements/cart';
@import'elements/registration';
@import'elements/faq';
@import'elements/shop';
@import'elements/billing-details';
@import'elements/checkout';
@import'elements/vendor';
@import'elements/account';
@import'elements/sidebar';
@import'elements/invoice';
@import'elements/mobile-menu';
@import'elements/search-input';
@import'elements/quickview';
@import'elements/preloader';
@import'elements/modal-over';
@import'default/print';
@import'elements/rtl';





