// category area start

.swiper-data{
    overflow: hidden;
}


.single-category-one{
    padding: 25px 20px;
    border-radius: 6px;
    border: 1px solid #E2E2E2;
    height: 161px;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    transition: .3s;
    &.height-180{
        height: 180px;
    }
    &.height-230{
        height: 230px;
        padding: 10px;
        background: #F3F4F6;
        .thumbnail{
            height: 140px;
            width: 100%;
            border: 1px solid #E2E2E2;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            img{
                transform: scale(1.2);
                margin-bottom: 30px;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.3);
                }
            }
        }
        .inner-content-category{
            text-align: center;
            margin-top: 15px;
        }
    }
    cursor: pointer;
    img{
        max-width: 70px;
        max-height: 94px;
        margin: auto;
        margin-bottom: 18px;
        min-height: 60px;
        text-align: center;
        display: flex;
    }
    p{
        font-weight: 700;
        color: #2C3C28;
        font-size: 14px;
        margin-bottom: 8px;
        text-align: center;
    }
    span{
        color: var(--color-primary);
        text-transform: uppercase;
    }
    &:hover{
        border: 1px solid #629D23;
    }
}
.cover-card-main-over {
    padding: 30px;
    border-radius: 6px;
    background: #F5F6F7;
}

.cover-card-main-over-white{
    background: #fff;
    padding-bottom: 60px;
    padding: 30px 0;
    padding-top: 20px;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    .title-area-between {
        padding: 0 30px;
        border-bottom: 1px solid #E2E2E2;
        padding-bottom: 20px;
        padding-top: 0;
        @media #{$small-mobile} {
            padding: 0 10px;
            padding-bottom: 20px;
        }
    }
    .rts-caregory-area-one {
        padding: 0 30px;
    }
}


.single-feature-card{
    background-image: url(../images/category/01.jpg);
    height: 400px;
    border-radius: 6px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    @media #{$laptop-device} {
        padding: 20px;
    }
    @media #{$smlg-device} {
        padding: 20px;
        height: 300px;
    }
    &.style-three{
        height: 350px;
        background-image: url(../images/category/05.jpg);
        &.two{
            background-image: url(../images/category/06.jpg);
        }
        &.three{
            background-image: url(../images/category/07.jpg);
        }
    }
    &.two{
        background-image: url(../images/category/02.jpg);
    }
    &.three{
        background-image: url(../images/category/03.jpg);
    }
    &.four{
        background-image: url(../images/category/04.jpg);
    }
    .rts-btn{
        margin-bottom: 15px;
        padding: 7px 26px;
        &:focus{
            box-shadow: none;
            border: none;
        }
    }
    .title{
        line-height: 1.2;
        font-size: 26px;
        margin-bottom: 20px;
        @media #{$smlg-device} {
            font-size: 18px;
        }
        span{
            font-weight: 400;
            color: var(--color-primary);
        }
    }
}


.category-area-main-wrapper-two{
    .mySwiper-category-1{
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        border-right: .5px solid #e2e2e2;
    }
    .single-category-one{
        border-radius: 0;
        text-align: center;
        padding: 25px 9px;
        border: none;
        border-right: 1px solid #e2e2e2;
        position: relative;
        p{
            margin-bottom: 4px;
        }
        span{
            font-weight: 500;
            color: var(--color-primary);
            text-transform: uppercase;
            font-size: 12px;
        }
        &::after{
            bottom: 0;
            position: absolute;
            left: 0;
            height: 4px;
            width: 0%;
            background: var(--color-primary);
            content: '';
            transition: .3s;
        }
        &:hover{
            &::after{
                width: 100%;
            }
        }
    }
}