
// modal popup area start
.modal-compare-area-start{
    .modal-dialog{
        max-width: 1800px;
        border: none;
        
        .modal-title{
            margin-left: 25px;
            font-size: 36px !important;
            font-weight: 500;
        }
    }
    .modal-dialogs{
        max-width: 1800px;
        border: none;
        width: 100vw;
        overflow-x: auto;
        overflow-x: auto;
        --webkit-overflow-scrolling: touch;
        --webkit-overflow-scrolling: touch;
        .modal-content{
            border: 1px solid #f1f1f1;
            width: 1600px;   
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            --webkit-overflow-scrolling: touch;
        }
        .modal-title{
            margin-left: 25px;
            font-size: 36px !important;
            font-weight: 500;
        }
    }
    .modal-body{
        padding: 30px;
    }

}

.compare-main-wrapper-body{
    padding: 0 50px;
    border: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>div{
        flex-basis: 25%;
    }
    .single-compare-elements{
        display: block;
        height: 100%;
        position: relative;
        height: 250px;
        display: flex;
        align-items: center;
        width: 25%;
        justify-content: center;
        .instocks{
            span{
                display: block;
                padding: 4px 10px;
                border-radius: 2px;
                border: 2px solid 2px;
                background: #DEF9EC;
                color: #3BB77E;
            }
        }
        .outstocks{
            span{
                display: block;
                padding: 4px 10px;
                border-radius: 2px;
                border: 2px solid 2px;
                background: #DEF9EC;
                color: #3BB77E;
                &.out-stock{
                    color: #fff;
                    background: var(--color-danger);
                }
            }
        }
        .rating{
            display: flex;
            gap: 5px;
            align-items: center;
            i{
                
            color: #FF9A00;
            }
            span{
                color: #000;
                margin-left: 7px;
                font-size: 18px;
            }
        }
        &.price{
            p{
                font-size: 32px !important;
                font-weight: 600;
                color: var(--color-primary);
                margin: 0;
            }
        }
        &.name{
            &::after{
                right: 0;
            }
        }
        &::after{
            position: absolute;
            content: '';
            height: 250px;
            width: 1px;
            background: #f1f1f1;
            top: 0;
            right: 0;
        }
        &:last-child{
            &::after{
                display: none;
            }
        }
        &.discription{
            text-align: center;
            padding: 0 15px;
        }
    }
    &.productname{
        &>div{
            flex-basis: 25%;
            height: 100px;
            &::after{
                position: absolute;
                content: '';
                height: 100px;
                width: 1px;
                background: #f1f1f1;
                top: 0;
                right: 0;
            }
        }
        &.spacifiq{
            .single-compare-elements{
                p{
                    font-size: 20px;
                    font-weight: 500;  
                }
            }
        }

    }
}