

// rtl area start
.rts-ltr-rtl-setting-button-area{
    display: flex;
    align-items: center;
    gap: 10px;
    background: #fff;
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 10;
    transition: .3s;
    right: -90px;
    i{
        padding: 15px;
        cursor: pointer;
        color: var(--color-primary);
    }
    .ltr-rtl-area{
        display: flex;
        align-items: center;
    }
    &.active{
        right: 0;
    }
}


[dir="rtl"]{
    .rts-ltr-rtl-setting-button-area {
        display: flex;
        align-items: center;
        gap: 10px;
        background: #fff;
        position: fixed;
        right: 0;
        top: 50%;
        z-index: 10;
        transition: 0.3s;
        right: -90px; 
        flex-direction: row-reverse;
        &.active{
            right: 0;
        }
    }
}