

// invoice area
.invoice-main-wrapper-1{
    margin-top: 120px;
    padding: 50px;
    border-radius: 6px;
    background:  #f1f1f1;
    margin-bottom: 30px;
    .logo-top-area{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .invoice-location{
        text-align: right;
        .title{
            font-weight: 500;
            line-height: 1;
            font-size: 48px;
        }
        span{
            display: block;
            font-size: 18px;
            margin: 5px 0;
        }
    }
}
.invoice-banner{
    background-image: url(../images/invoice/01.jpg);
    height: 177px;
    margin-top: 30px;
    border-radius: 5px;
}



.invoice-3 {
    .invoice-table {
        thead {
            color: #fff !important;
            background-color: #f1f1f1;
        }
    }
}
.invoice-4 {
    .invoice-info {
        border-radius: 5px;
    }
    .invoice-header {
        border-bottom: 1px solid #f1f1f1;
        padding: 50px 150px 30px 150px;
        margin-bottom: 50px;
    }
    .invoice-bottom {
        .hr {
            width: 100%;
            height: 1px;
            background-color: #f1f1f1;
        }
    }
}
.invoice-5 {
    .invoice-info {
        border-radius: 0;
    }
    .invoice-header {
        padding: 50px 150px;
    }
    .invoice-banner {
        padding: 0 150px 50px 150px;
    }
    .invoice-bottom {
        .hr {
            width: 100%;
            height: 1px;
            background-color: #f1f1f1;
        }
    }
}


.invoice-center-rts{
    margin-top: 30px;
    thead{
        tr{
            th{
                color: #4d4d4d;
                padding: 15px 20px;
                border-color: #f1f1f1;
                border-width: 0;
                font-weight: 600;
            }
        }
    }
    
    .item-desc-1{
        padding: 12px 20px;
    }
    td{
        padding: 15px 20px;
    }
}

.invoice-area-bottom{
    text-align: center;
    margin-top: 40px;
    .powerby{
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: center;
        p{
            margin-bottom: 0;
            font-size: 32px;
            font-weight: 500;
            color: var(--color-heading-1);
        }
        img{
            max-width: 50px;
        }
    }
    p{
        margin: 0;
        margin-top: 20px;
        font-weight: 400;
        color: var(--color-heading-1);
    }
}

.text-end.f-w-600{
    font-weight: 600;
}

.buttons-area-invoice{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}