@media print {
    @page {
        size: A4;
        margin: 20mm;
    }

    body {
        -webkit-print-color-adjust: exact;
        font-family: Arial, sans-serif;
    }

    .invoice-main-wrapper-1 {
        width: 100%;
        max-width: 210mm;
        margin: auto;
        padding: 20px;
        box-sizing: border-box;
        background: white;
    }

    .logo-top-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .logo img {
        max-width: 150px;
    }

    .invoice-location {
        text-align: right;
    }

    .invoice-location .title {
        font-size: 24px;
        margin-bottom: 5px;
    }

    .invoice-location .number,
    .invoice-location .email,
    .invoice-location .website {
        display: block;
        font-size: 14px;
    }

    .invoice-banner {
        display: none;
    }

    .invoice-center-rts {
        margin-top: 20px;
    }

    .table-responsive {
        width: 100%;
        overflow-x: auto;
    }

    .invoice-table {
        width: 100%;
        border-collapse: collapse;
    }

    .invoice-table th,
    .invoice-table td {
        border: 1px solid #000;
        padding: 8px;
    }

    .invoice-table thead th {
        background-color: #f2f2f2;
    }

    .invoice-table .text-center {
        text-align: center;
    }

    .invoice-table .text-right {
        text-align: right;
    }

    .invoice-table .text-end {
        text-align: right;
    }

    .f-w-600 {
        font-weight: 600;
    }

    .invoice-area-bottom {
        margin-top: 20px;
        text-align: center;
        font-size: 12px;
    }

    .invoice-area-bottom .powerby {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .invoice-area-bottom .powerby img {
        max-width: 50px;
        margin-left: 5px;
    }

    .invoice-area-bottom p {
        margin: 5px 0;
    }
    .no-print {
        display: none !important;
    }
}