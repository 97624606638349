/**------------------------------------------------------------------
 * Preloader Start Here
 */
 #rts__preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  
  @keyframes preloader {
    0% {
      transform: scale(60%) rotate(0deg);
    }
  
    50% {
      transform: scale(60%) rotate(90deg);
    }
  
    100% {
      transform: scale(60%) rotate(360deg);
    }
  }
  
  @keyframes preloaderbg {
    0% {
      background: #fff;
      backdrop-filter: blur(9px);
    }
  
    100% {
      background: rgba(255, 255, 255, 0);
  
    }
  }
  
  .preloader {
    background: #fff;
    color: #000;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &-mask {
      overflow: hidden;
      padding-right: 2px;
    }
  
    &-text {
      margin: 0;
      font-size: 40px;
      transform: translateY(135%);
      transition: transform .3s cubic-bezier(.4, -0.19, .42, 1.3);
    }
  
    .animate {
      animation-duration: .6s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(.4, -0.19, .42, 1.3);
  
      &.slide-up {
        animation-name: slideUp;
      }
  
      &.delay-1 {
        animation-delay: .2s
      }
  
      &.delay-2 {
        animation-delay: .4s
      }
    }
  
    &.loaded {
      .animate {
        animation-name: slideDown;
        animation-delay: .6s;
  
        &.delay-1 {
          animation-delay: .4s
        }
  
        &.delay-2 {
          animation-delay: .2s;
        }
      }
    }
  }
  
  
  @keyframes slideUp {
    0% {
      transform: translateY(135%);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(0);
    }
  
    100% {
      transform: translateY(-135%);
    }
  }
  

  #weiboo-load {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: -webkit- flex;
    display: -ms- flex;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    background: #ffffff;
    position: fixed;
    z-index: 999999;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }



.preloader-new {
    text-align: center;
    max-width: 20em;
    width: 100%;
}


.cart_preloader {
    display: block;
    margin: 0 auto 1.5em auto;
    width: 8em;
    height: 8em;
}

.cart__lines,
.cart__top,
.cart__wheel1,
.cart__wheel2,
.cart__wheel-stroke {
    animation: cartLines 2s ease-in-out infinite;
}

.cart__lines {
    stroke: var(--color-primary);
}

.cart__top {
    animation-name: cartTop;
}

.cart__wheel1 {
    animation-name: cartWheel1;
    transform: rotate(-0.25turn);
    transform-origin: 43px 111px;
}

.cart__wheel2 {
    animation-name: cartWheel2;
    transform: rotate(0.25turn);
    transform-origin: 102px 111px;
}

.cart__wheel-stroke {
    animation-name: cartWheelStroke
}

.cart__track {
    stroke: hsla(var(--hue), 10%, 10%, 0.1);
    transition: stroke .4s;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: #000;
        --fg: #fff;
    }

    .cart__track {
        stroke: #000;
    }
}

/* Animations */
@keyframes msg {
    from {
        opacity: 1;
        visibility: visible;
    }

    99.9% {
        opacity: 0;
        visibility: visible;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes cartLines {

    from,
    to {
        opacity: 0;
    }

    8%,
    92% {
        opacity: 1;
    }
}

@keyframes cartTop {
    from {
        stroke-dashoffset: -338;
    }

    50% {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: 338;
    }
}

@keyframes cartWheel1 {
    from {
        transform: rotate(-0.25turn);
    }

    to {
        transform: rotate(2.75turn);
    }
}

@keyframes cartWheel2 {
    from {
        transform: rotate(0.25turn);
    }

    to {
        transform: rotate(3.25turn);
    }
}

@keyframes cartWheelStroke {

    from,
    to {
        stroke-dashoffset: 81.68;
    }

    50% {
        stroke-dashoffset: 40.84;
    }
}

.banner.banner-1 .bannerSlide2 .single-inner .content-box .slider-subtitle{
    webkit-animation: fadeInDown 1.7s ease-in-out;
    animation: fadeInDown 1.7s ease-in-out;
}

.banner.banner-1 .bannerSlide2 .single-inner .content-box .slider-title{
    -webkit-animation: fadeInUp 1.2s ease-in-out;
    animation: fadeInUp 1.2s ease-in-out;
}

.banner.banner-1 .bannerSlide2 .single-inner .content-box .slider-description{
    -webkit-animation: fadeInUp 1.6s ease-in-out;
    animation: fadeInUp 1.6s ease-in-out;
}

.banner.banner-1 .bannerSlide2 .single-inner .content-box .slider-btn2{
    -webkit-animation: fadeInUp 1.8s ease-in-out;
    animation: fadeInUp 1.8s ease-in-out;
} 