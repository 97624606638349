


.quickview-modal{
    .modal-dialog{
        max-width: 1080px;
        margin: auto;
        .modal-header{
            border: none;
        }
    }
}


.product-single-main-wrapper-single-left-checkout-slide{
    .banner-horizental{
        margin-bottom: 40px;
    }
    .banner-horizental .swiper {
        overflow: hidden;
    .slider-inner img {
            width: 100%;
        }
    }
 
}



// custom opup
.rts-product-details-section {
    padding-top: 100px;
    padding-bottom: 30px;

    @media(max-width:768px) {
        .product-thumb-area {
            margin-right: 30px;
        }

        .contents {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin: 0 auto;
        }
    }

    @media(max-width: 900px) {
        .details-product-area {
            flex-direction: column;

            .product-thumb-area {
                width: 100%;
                margin: 0;
                margin-bottom: 20px;
                flex-direction: column;

                .thumb-wrapper {
                    width: 100%;
                }

                .product-thumb {
                    margin-bottom: 20px;
                }

                .product-thumb-filter-group {
                    margin-left: 0;
                    flex-direction: row;

                    .thumb-filter {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    @media(max-width: 768px) {
        padding-top: 50px;
    }

    .product-thumb-area {
        display: flex;
        align-items: center;
        margin-right: 70px;
    }

    .product-thumb-filter-group {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        &:last-child{
            .thumb-filter{
                margin-right: 0;
            }
        }
        .thumb-filter {
            max-width: 85px;
            opacity: 40%;
            margin-bottom: 20px;
            cursor: pointer;
            transition: opacity .5;

            &.active {
                opacity: 1;
            }
        }
    }

    .product-row {
        padding-bottom: 10px;
        margin-bottom: 70px;
        border-bottom: 1px solid #f0f0f0;
    }

    .col-lg-25 {
        width: 20%;

        @media(max-width: 1200px) {
            display: none;
        }
    }

    .side-products {
        .product-item {
            .product-image {
                max-width: 225px !important;
            }
        }
    }

    .details-product-area {
        display: flex;
        align-items: flex-start;
        @media #{$smlg-device} {
            flex-wrap: wrap;
        }
        @media(max-width:576px) {
            margin-bottom: 30px;
        }

        table {
            margin-bottom: 30px;

            @media(max-width:768px) {
                margin-top: 30px;
            }

            @media(max-width:576px) {
                margin-bottom: 0;
            }

            tr {
                border-bottom: 1px solid #f4f4f4;

                @media(max-width:768px) {
                    border: none;
                    margin-bottom: 40px;
                }

                .first-child {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    @media(max-width:768px) {
                        display: block;
                        text-align: center;
                    }

                    .image-section {
                        width: 20%;

                        @media(max-width:768px) {
                            width: 100%;
                        }

                        img {
                            @media(max-width:768px) {
                                width: 50%;
                            }
                        }
                    }

                    .text {
                        display: grid;
                        margin-left: 20px;

                        @media(max-width:768px) {
                            margin: 10px 0 0 0;
                        }

                        a {
                            font-size: 14px;
                            color: #333333;

                            @media(max-width:768px) {
                                font-size: 16px;
                            }
                        }

                        .color {
                            color: #222222;
                            font-size: 12px;
                            line-height: 1.6;
                            padding: 5px 0;

                            @media(max-width:768px) {
                                font-size: 14px;
                            }
                        }

                        .price {
                            color: #000;
                            font-size: 13px;

                            @media(max-width:768px) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .cart-edit {
                    @media(max-width:768px) {
                        margin: 10px 0 0 0;
                    }

                    .quantity-edit {
                        width: 110px;
                        height: 50px;

                        @media(max-width:768px) {
                            width: 50%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }

        .product-status {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        .product-catagory {
            background: var(--color-primary);
            padding: 0px 10px;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            margin-right: 20px;
        }

        .rating-stars-group {
            .rating-star {
                i {
                    color: #ffcd00;
                    font-size: 15px;
                }
            }

            span {
                font-size: 14px;
                font-weight: 400;
                color: #61b163;
            }
        }

        .product-title {
            font-size: 26px;
            margin-bottom: 10px;
            @media #{$large-mobile} {
                font-size: 20px;
            }
        }

        .stock {
            font-size: 12px;
            font-weight: 600;
            color: #98bd25;
            padding: 2px 7px;
            border: 1px solid #ededed;
            border-radius: 4px;
            margin-left: 5px;
            transform: translateY(-3px);
        }

        .stock1 {
            font-size: 12px;
            font-weight: 600;
            color: #ff0000;
            padding: 3px 7px;
            border: 1px solid #ededed;
            border-radius: 4px;
            margin-left: 5px;
            transform: translateY(-3px);
        }


        .product-price {
            margin-bottom: 30px;
            font-size: 36px;
            color: var(--color-primary);

            .old-price {
                font-size: 20px;
                font-weight: 600;
                color: #cfcfcf;
                text-decoration: line-through;
            }
        }

        p {
            color: var(--color-body);
            max-width: 540px;
            margin-bottom: 20px;
        }

        .action-item2 {
            padding: 10px 0;
            border-radius: 6px;
            margin-bottom: 10px;

            .action-top {
                padding-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .action-title {
                    font-size: 16px;
                }

                .down-icon {
                    i {
                        font-size: 17px;
                    }
                }
            }

            .category-item {
                &:last-child {
                    .category-item-inner {
                        margin-bottom: 0;
                    }
                }

                .category-item-inner {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    margin-bottom: 10px;

                }

                .category-title-area {
                    display: flex;
                    align-items: center;
                }

                .point {
                    background: #e3e3e3;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .category-title {
                    font-size: 14px;
                    color: #61b163;
                    font-weight: 500;
                }

                i {
                    font-size: 14px;
                    color: #000;
                }

                .sub-categorys {
                    margin-left: 20px;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height .5;

                    .sub-categorys-inner {
                        padding-bottom: 15px;
                    }

                    a {
                        font-size: 14px;
                        color: #61b163;
                        font-weight: 500;

                        &:hover {
                            color: #000;
                        }
                    }
                }

                &.show {
                    .sub-categorys {
                        max-height: 500px;
                    }

                    .category-item-inner {
                        .down-icon {
                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            .color-item2 {
                display: inline-flex;
                align-items: center;
                margin-bottom: 10px;
                cursor: pointer;

                &:hover {
                    .color-arrow {
                        color: #000;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.selected {
                    .color {

                        i {
                            display: block;
                        }
                    }
                }

                .color {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    margin-right: 10px;


                    i {
                        display: none;
                        color: white;
                        font-size: 8px;
                        transform: translateX(3px) translateY(2px);

                        &::before {
                            content: ("\f00c");
                        }
                    }

                    &.black {
                        background: #000;
                    }

                    &.blue {
                        background: #0084ff;
                    }

                    &.gray {
                        background: #d4d4d4;
                    }

                    &.green {
                        background: #61b163;
                    }

                    &.red {
                        background: #ff6262;
                    }

                    &.yellow {
                        background: #ffc000;
                    }
                }

                .color-name {
                    font-size: 14px;
                    color: #61b163;
                }

                .color-arrow {
                    margin-left: auto;
                    color: #b9b9b9;
                }
            }

            .product-brands {
                .brands-inner {
                    max-height: 400px;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: 3px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: var(--color-primary);
                        border-radius: 0;
                        border: none;
                    }
                }

                a {
                    font-size: 14px;
                    color: #61b163;
                    margin-bottom: 10px;
                    font-weight: 500;

                    &:hover {
                        color: #000;
                    }
                }
            }

        }

        .action-item3 {
            padding: 10px 0;
            border-radius: 6px;
            margin-bottom: 30px;

            .action-top {
                padding-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .action-title {
                    font-size: 16px;
                }

                .down-icon {
                    i {
                        font-size: 17px;
                    }
                }
            }

            .category-item {
                &:last-child {
                    .category-item-inner {
                        margin-bottom: 0;
                    }
                }

                .category-item-inner {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    margin-bottom: 10px;

                }

                .category-title-area {
                    display: flex;
                    align-items: center;
                }

                .point {
                    background: #e3e3e3;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .category-title {
                    font-size: 14px;
                    color: #61b163;
                    font-weight: 500;
                }

                i {
                    font-size: 14px;
                    color: #000;
                }

                .sub-categorys {
                    margin-left: 20px;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height .5;

                    .sub-categorys-inner {
                        padding-bottom: 15px;
                    }

                    a {
                        font-size: 14px;
                        color: #61b163;
                        font-weight: 500;

                        &:hover {
                            color: #000;
                        }
                    }
                }

                &.show {
                    .sub-categorys {
                        max-height: 500px;
                    }

                    .category-item-inner {
                        .down-icon {
                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            .color-item2 {
                display: inline-flex;
                align-items: center;
                margin-bottom: 10px;
                cursor: pointer;
                transition: all .2s ease;

                &:hover {
                    .color-arrow {
                        color: #000;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.selected {
                    .color {

                        i {
                            display: block;
                        }
                    }
                }

                .size {
                    padding: 2px 10px;
                    background-color: #f5f5f5;
                    margin-right: 10px;
                    border: 1px solid #f4f4f4;

                    transition: all .3s ease;

                    &:hover {
                        border: 1px solid #000;
                    }
                }

                .color-name {
                    font-size: 14px;
                    color: #61b163;
                }

                .color-arrow {
                    margin-left: auto;
                    color: #b9b9b9;
                }
            }

            .product-brands {
                .brands-inner {
                    max-height: 400px;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: 3px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color:var(--color-primary);
                        border-radius: 0;
                        border: none;
                    }
                }

                a {
                    font-size: 14px;
                    color: #61b163;
                    margin-bottom: 10px;
                    font-weight: 500;

                    &:hover {
                        color: #000;
                    }
                }
            }

        }

        .product-bottom-action {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            flex-wrap: wrap;

            @media(max-width: 500px) {
                .action-item {
                    margin-bottom: 20px;
                    padding: 0 17px;
                    height: 50px;
                }

                button {}

                .wishlist {
                    margin-bottom: 0;
                }
            }
        }

        .product-bottom-action2 {
            margin: 30px 0 30px 0;

            .form-btn {
                background: #f1f1f1;
                color: #ffffff;
                font-size: 16px;
                font-weight: 700;
                line-height: 1;
                text-align: center;
                width: 100%;
                padding: 21px 40px;
                border: 1px solid #f1f1f1;
                border-radius: 7px;
                -webkit-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
        }

        .product-bottom-action3 {
            margin: 30px 0 30px 0;

            .cart-edit2 {
                margin-bottom: 20px;
                display: flex;
                align-items: center;

                .quantity-edit {
                    width: 110px;
                    height: 45px;
                    display: flex;
                    padding: 3px 10px;
                    border-radius: 5px;

                    input {
                        text-align: center;
                        max-width: 55px;
                        font-size: 16px;
                        font-weight: 700;
                        color: #000;
                    }

                    button {
                        background: none;
                        font-size: 0;

                        i {
                            font-size: 16px;
                            color: #d3d3d3;

                            &:hover {
                                color: #ff0000;
                            }
                        }
                    }
                }

                span {
                    margin-left: 20px;
                    font-size: 14px;
                    font-weight: 500;

                    i {
                        color: #ff0000;
                    }
                }
            }

            .form-btn {
                background: #f1f1f1;
                color: #ffffff;
                font-size: 20px;
                font-weight: 700;
                line-height: 1;
                text-align: center;
                pointer-events: none;
                width: 100%;
                padding: 18px 40px;
                border: 0;
                border-radius: 7px;
                -webkit-transition: all 0.3s ease 0s;
                transition: all 0.3s ease 0s;

                &:hover {
                    i {
                        transform: translateX(10px);
                    }
                }

                i {
                    margin-left: 10px;
                    padding-top: 5px;
                }
            }
        }

        .product-bottom-action4 {
            margin-bottom: 20px;

            .button {
                display: flex;
                align-items: center;

                .form-btn1 {
                    background: #f1f1f1;
                    color: #fff;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 1;
                    text-align: center;
                    width: 100%;
                    padding: 18px 40px;
                    margin-right: 10px;
                    border: 1px solid #f1f1f1;
                    border-radius: 7px;
                    -webkit-transition: all 0.3s ease 0s;
                    transition: all 0.3s ease 0s;

                    &:hover {
                        background: none;
                        color: #f1f1f1;
                    }

                    i {
                        margin-right: 10px;
                        transition: all 50ms;
                    }

                }
            }
        }

        .action-item {
            border-style: solid;
            border-width: 1px;
            border-color: #e2e2e2;
            border-radius: 6px;
            height: 49px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .cart-edit,
        .cart-edits {
            margin-right: 20px;
            display: flex;
            align-items: center;

            .quantity-edit {
                width: 123px;
                display: flex;
                padding: 3px 10px;
                border-radius: 5px;

                input {
                    text-align: center;
                    max-width: 55px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #000;
                }

                button {
                    background: none;
                    font-size: 0;

                    i {
                        font-size: 16px;
                        color: #d3d3d3;
                    }
                }
            }

            span {
                margin-left: 20px;
                font-size: 14px;
                font-weight: 500;

                i {
                    color: #ff0000;
                }
            }
        }

        .addto-cart-btn {
            margin-right: 20px;
            padding: 0 30px;
            background: var(--color-primary);
            color: #fff;
            font-weight: 700;
            border: none;
            
            &:hover {
                background: var(--color-heading-1);
                color: #f1f1f1;
            }

            i {
                margin-right: 4px;
            }

            @media(max-width: 768px) {
                font-size: 14px;
            }
        }

        .wishlist-btn {
            padding: 0 20px;

            &:hover {
                border: 1px solid #000;

                i {
                    color: #000;
                }
            }

            i {
                color: #e2e2e2;
            }
        }

        .product-uniques {
            margin-bottom: 20px;
        }

        .product-unipue {
            font-size: 16px;
            color: var(--color-body);
            font-weight: 400;
            display: block;
            margin-bottom: 5px;

            span {
                display: inline-block;
                font-weight: 700;
            }
        }

        .share-social {
            span {
                margin-right: 3px;
            }

            .platform {
                font-size: 16px;
                margin-right: 10px;
                color: #000;

                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }

    .product-full-details-area {
        .details-filter-bar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 30px;

            .details-filter {
                font-size: 16px;
                margin-right: 50px;
                font-weight: 700;
                color: #000;

                &.active {
                    color: #f1f1f1;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .details-filter-bar2 {
            display: block;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 30px;

            .details-filter {
                font-size: 16px;
                margin-right: 50px;
                font-weight: 700;
                color: #000;

                @media(max-width:768px) {
                    margin-right: 20px;
                }

                &.active {
                    color: #f1f1f1;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .full-details-inner {
            margin-bottom: -20px;

            p {
                transition: height 500ms;
                max-width: unset;
                line-height: 26px;
            }
        }

        @media(max-width: 1200px) {
            .details-filter-bar {
                flex-wrap: wrap;
                flex-direction: row;
            }
        }

        @media(max-width: 768px) {
            .details-filter-bar {
                .details-filter {
                    font-size: 14px;
                    margin-right: 30px;
                }
            }
        }

        @media(max-width: 507px) {
            .details-filter-bar {
                .details-filter {
                    font-size: 14px;
                    margin-right: 30px;
                    margin-bottom: 10px;
                }
            }
        }

        .full-details.dls-three.filterd-items {
            width: 100%;

            .full-details-inner {
                p {
                    font-size: 18px;
                    color: #777777;
                    margin: 0 0 15px;
                }

                .reveiw-form {
                    .section-title {
                        color: #000;
                        margin-bottom: 20px;

                        a {
                            color: #000;

                            &:hover {
                                color: #000;
                                text-decoration: underline;
                            }
                        }
                    }

                    .sect-title {
                        font-weight: 400;
                    }
                }

                .reveiw-form-main {
                    .post-title {
                        font-size: 26px;
                        margin-bottom: 35px;
                        padding-top: 30px;

                        @media(max-width:450px) {
                            font-size: 20px;
                        }
                    }

                    .contact-form {
                        padding: 30px 40px 35px 0;

                        .input-box {
                            position: relative;
                            z-index: 1;
                            margin-bottom: 25px;

                            &.text-input::before {
                                content: "\f303";
                            }

                            &.mail-input::before {
                                content: "\f0e0";
                            }

                            &.sub-input::before {
                                content: "\f0ac";
                            }

                            &::before {
                                content: "\f007";
                                left: auto;
                                right: 30px;
                                font-size: 14px;
                                font-family: "Font awesome 5 Pro";
                                position: absolute;
                                font-size: 16px;
                                color: #f1f1f1;
                                top: 17px;
                                z-index: 2;
                            }


                            textarea {
                                border: 2px solid #f4f4f4;
                                box-shadow: none;
                                padding-left: 30px;
                                color: #000;
                                font-size: 14px;
                                background-color: #ffffff;
                                width: 100%;
                                height: 281px;
                                border-radius: 7px;
                                padding-top: 16px;
                                resize: none;
                                position: relative;
                                z-index: 1;

                                @media(max-width:450px) {
                                    height: 150px;
                                }
                            }

                            input {
                                border: 2px solid #f4f4f4;
                                box-shadow: none;
                                padding-left: 30px;
                                height: 60px;
                                border-radius: 7px;
                                color: #000;
                                font-size: 14px;
                                background-color: #ffffff;
                                position: relative;
                                width: 100%;
                                z-index: 1;
                            }
                        }

                        .rating {
                            margin: 25px 0;
                            display: flex;
                            align-items: center;

                            p {
                                margin: 0;
                            }

                            .rating-icon {
                                margin-left: 30px;

                                a {
                                    position: relative;
                                    transition: all 500ms;

                                    &::after {
                                        display: none;
                                        content: "\f005";
                                        left: auto;
                                        right: 0;
                                        font-size: 14px;
                                        font-family: "Font awesome 5 Pro";
                                        position: absolute;
                                        font-size: 16px;
                                        color: #ff0000;
                                        top: 0;
                                        z-index: 2;
                                    }

                                    &:hover {
                                        &::after {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .form-btn {
                        background: #f1f1f1;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 1;
                        text-align: center;
                        width: 100%;
                        padding: 21px 40px;
                        border: 0;
                        border-radius: 7px;
                        transition: all 0.3s ease 0s;

                        &:hover {
                            i {
                                transform: translateX(10px);
                            }
                        }

                        i {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

.rating-stars-group{
    display: flex;
    align-items: center;
    gap: 10px;
}

@media(max-width: 1024px) {
    .product-area {
        flex-direction: column;
        align-items: flex-start;

        .product-thumb-area {
            margin: 0 auto;
            margin-bottom: 30px;
            flex-direction: column;

            .product-thumb {
                margin-bottom: 30px;
            }
        }

        .product-thumb-filter-group {
            flex-direction: row;

            .details-filter {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }

        .product-bottom-action {
            .action-item {
                height: 50px;
                padding: 15px;
            }
        }
    }
}

.rts-product-details-section2 {
    padding-bottom: 0;
    .product-thumb-area {
        display: flex;
        flex-direction: column;
        .product-thumb {
            margin-bottom: 30px;
            border: 1px solid #f1f1f1;
            border-radius: 6px;
        }
        .product-thumb-filter-group {
            flex-direction: row;
            .thumb-filter {
                margin-bottom: 0;
                margin-right: 10px;
                border: 1px solid #f1f1f1;
                border-radius: 6px;
            }
        }
    }
    .contents{
        table{
            width: 100%;
        }
    }

    @media(max-width: 1200px) {
        .details-product-area {
            flex-direction: column;

            .product-thumb-area {
                width: 100%;
                margin: 0;
                margin-bottom: 20px;
                flex-direction: column;

                .thumb-wrapper {
                    width: 100%;
                }

                .product-thumb {
                    margin-bottom: 20px;
                }

                .product-thumb-filter-group {
                    margin-left: 0;
                    flex-direction: row;

                    .thumb-filter {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}


.product-full-details-area2 {
    display: flex;

    .details-filter-bar {
        margin-right: 30px;
        border: none;

        .details-filter {
            width: 220px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            margin-bottom: 10px;
            border: 1px solid #ebebeb;

            &.active {
                background: #f1f1f1;
                color: #fff !important;
                border: 1px solid transparent;

            }
        }
    }

    @media(max-width: 1200px) {
        flex-direction: column;

        .details-filter-bar {
            display: flex;
            flex-wrap: wrap;
            margin-right: 0;

            .details-filter {
                margin-bottom: 0;
                margin-right: 10px;
            }

        }
    }


    @media(max-width: 950px) {
        .details-filter-bar {
            .details-filter {
                margin-bottom: 10px;
            }
        }
    }

    @media(max-width: 768px) {
        .details-filter-bar {
            margin-right: 0 !important;

            .details-filter {
                margin-bottom: 10px;
                width: 170px;
                height: 70px;
                font-size: 14px;
            }

        }
    }

    @media(max-width: 450px) {
        .details-filter-bar {

            .details-filter {
                width: 100%;
                margin-right: 0;
            }

        }
    }
}

.rts-featured-product-section-inner {
    padding: 80px 0;

    .go-btn {
        color: #000;
    }
}


.product-details-popup-wrapper {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    pointer-events: none;
    // background: #000000e0;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    &.popup {
        visibility: visible;
        opacity: 1;

        .product-details-popup {
            pointer-events: all !important;
        }
    }
}

.rts-product-details-section.product-details-popup-section {
    position: relative;
    border: none;

    @media (max-width: 1200px) {
        .details-product-area {
            .contents {
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                margin: 0;
            }
        }
    }

    @media (max-width: 900px) {
        .details-product-area {
            flex-direction: column;

            .contents {
                max-height: 450px;
                overflow-y: scroll;
            }
        }
    }

    @media (max-width: 768px) {
        .product-details-popup {
            margin-top: 100px;
            padding: 20px;
            width: 92%;
            height: 90vh;
            overflow-y: scroll;
            @media #{$large-mobile} {
                margin-top: 30px;
            }
            @media #{$small-mobile} {
                margin-top: 20px;
            }
        }

        .details-product-area {
            flex-direction: column;

            .product-thumb-area {
                width: 100%;
                margin: 0 !important;
                margin-bottom: 30px !important;

                .product-thumb {
                    width: 100%;
                }
            }

            .contents {
                max-height: unset;
                overflow: visible;
            }
        }
    }
}

.product-details-popup {
    pointer-events: none;
    margin: 0 auto;
    height: 520px;
    border-radius: 7px;
    background: #fff;
    padding: 30px !important;
    position: relative;
    z-index: 999;

    .product-thumb-area {
        margin-right: 40px !important;
    }

    .product-thumb {
        width: 303px;
    }

    .product-details-close-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -50px;
        top: -50px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;

        &:hover {
            i {
                color: red;
            }
        }

        i {
            font-size: 20px;
            color: #000;
        }

        @media(max-width: 1200px) {
            right: 0;
        }
    }

    @media(max-width: 1200px) {
        width: 90%;
    }
}
.filterd-items {
    visibility: visible;
    opacity: 1;
    position: relative;
    transition: all 1.2s;
    height: auto;
    img{
        transition: all 1.2s;
    }
  }
  
  .filterd-items.hide {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transform: translateY(0);
    height: 0;
    overflow: hidden;
    transition: all 1.2s;
    img{
        transition: all 1.2s;
    }
  }
  
  .filterd-items2.hide {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: none;
    height: 0;
    overflow: hidden;
  }

.zoom img:hover {
    opacity: 0;
}
.zoom img {
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    display: block;
    width: 100%;
}

.product-details-popup {
    pointer-events: none;
    margin: 0 auto;
    height: 520px;
    border-radius: 7px;
    background: #fff;
    padding: 30px !important;
    position: relative;
    z-index: 999;

    .product-thumb-area {
        margin-right: 40px !important;
        cursor: url(../images/banner/shape/zoom.png), auto;
    }

    .product-thumb {
        width: 303px;
    }

    .product-details-close-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -50px;
        top: -50px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;

        &:hover {
            i {
                color: red;
            }
        }

        i {
            font-size: 20px;
            color: var(--color-heading-1);
        }

        @media(max-width: 1200px) {
            right: 0;
        }
    }

    @media(max-width: 1200px) {
        width: 90%;
    }
}


.successfully-addedin-wishlist{
    position: fixed;
    visibility: visible;
    opacity: 1;
    width: max-content;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    // visibility: hidden;
    // opacity: 0;
    display: none;
    transition: 0.3s;
    height: auto;
    z-index: 1000;
    padding: 15px 30px;
    background: #fff;
    gap: 20px;
    border-radius: 4px;
    border: 1px solid #E2E2E2;
    // &.popup{
    //     visibility: visible;
    //     opacity: 1;
    // }
    i{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid var(--color-success);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-white);
        font-weight: 600;
        background: var(--color-primary);
    }
    p{
        color: var(--color-success);
        font-size: 18px;
        line-height: normal;
        font-weight: 500;
    }
}




.product-details-popup-wrapper{
    &.in-shopdetails{
        width: 100%;
        position: static;
        top: 0%;
        left: 0%;
        transform: none;
        z-index: 99;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: 0.3s;
        height: auto;
        width: 100%;
        z-index: 1000;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        padding-top: 0 !important;
        & > div{
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }
        .product-details-popup-section{
            padding-top: 0;
        }
        .product-details-popup{
            pointer-events: auto;
            padding: 0 !important;
            height: auto;
            z-index: 5;
        }
        .product-details-popup .product-thumb {
            width: 450px;
        }
    }
}



.share-option-shop-details{
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 25px;
    @media #{$large-mobile} {
        flex-wrap: wrap;
    }
    .single-share-option{
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        span{
            color: #2C3C28;
            transition: .3s;
        }
        .icon{
            height: 35px;
            width: 35px;
            border-radius: 6px;
            border: 1px solid #E2E2E2;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s;
            i{
                color: #2C3C28;
                transition: .3s;
            }
        }
        span{
            color: #2C3C28;
            font-weight: 500;
            transition: .3s;
        }
        &:hover{
            .icon{
                background: var(--color-primary);
                i{
                    color: #fff;
                }
            }
            span{
                color: var(--color-primary);
            }
        }
    }
}

.shop-sight-sticky-sidevbar,
.our-payment-method{
    padding: 30px;
    border-radius: 6px;
    background: #F3F4F6;
    .single-offer-area{
        display: flex;
        align-items: flex-start;
        gap: 15px;
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #E2E2E2;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
        }
        &:last-child{
            border: none;
            margin: 0;
        }
        .icon{
            min-width: max-content;
            img{
                min-width: max-content;
            }
        }
        .details{
            p{
                margin-bottom: 0;
                font-size: 14px;
                color: #2C3C28;
            }
        }
    }
}

.our-payment-method{
    .title{
        color: var(--color-primary);
        font-size: 16px;
        font-weight: 600;
    }
    img{
        cursor: pointer;
    }
}

.single-tab-content-shop-details{
    margin-top: 30px;
    p.disc{
        margin-bottom: 25px;
    }
    .details-row-2{
        display: flex;
        align-items: flex-start;
        gap: 40px;
        @media #{$smlg-device} {
            flex-direction: column;
            gap: 15px;
            align-items: flex-start;
        }
        .left-area{
            display: block;
            min-width: max-content;
            @media #{$large-mobile} {
                min-width: 100%;
            }
            img{
                min-width: max-content;
                @media #{$large-mobile} {
                    min-width: 100%;
                }
            }
        }
    }
}

.product-discription-tab-shop{
    margin-top: 30px;
    ul{
        display: flex;
        align-items: center;
        gap: 12px;
        padding-bottom: 20px !important;
        @media #{$large-mobile} {
            gap: 0;
        }
        &.bottom-ul{
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            margin: 0;
            list-style: none;
            margin: 0;
            li{
                margin: 5px 0;
            }
        }
    }
    button{
        border: 1px solid rgba(43, 66, 38, 0.12) !important;
        padding: 13px 20px;
        font-weight: 700;
        color: #2C3C28;
        border-radius: 6px !important;
        &.active{
            background: var(--color-primary) !important;
            border: 1px solid var(--color-primary) !important;
            color: #fff !important;
        }
        &:hover{
            background: var(--color-primary) !important;
            border: 1px solid var(--color-primary) !important;
            color: #fff !important;
        }
    }
}

.mt-lg--30{
    @media #{$smlg-device} {
        margin-top: 30px;
    }
}

.shop-single-area-filter{
    .single-filter-box{
        background: #fff;
    }
}

.category-hover-header.language-hover{
    &:hover{
        background: transparent !important;
        a{
            color: var(--color-primary);
            background: transparent;
        }
    }
    .category-sub-menu{
        min-width: max-content;
        right: -20px;
        left: auto;
        gap: 0;
        li{
            margin: 0;
            display: block;
            width: 100%;
            padding: 0;
            a{
                padding: 5px 15px;
                display: flex;
                align-items: center;
                gap: 7px;
            }
        }
    }
}


.show-product-area-details{
    display: flex;
    align-items: center;
    @media #{$large-mobile} {
        flex-direction: column-reverse;
    }
    .product-thumb-filter-group.left{
        @media #{$large-mobile} {
            display: flex;
            align-items: center;
            flex-direction: row;
        }
    }
}


.product-thumb-filter-group.left{
    min-width: 150px;
    margin-right: 0 !important;
}
.weekly-best-seller-item-single{
    .thumbanil{
        position: relative;
    }
    .action-share-option{
        position: absolute;
        bottom: -0px;
        left: 50%;
        transform: translateX(-50%) rotateX(-90deg);
        display: flex;
        align-items: center;
        gap: 8px;
        height: 48px;
        border-radius: 10px 10px 0 0;
        background: var(--color-primary);
        padding: 10px 29px;
        transform-origin: bottom;
        transition: .4s cubic-bezier(0.375, 1.185, 0.92, 0.975);
        .single-action{
            height: 28px;
            width: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1.2px dashed rgba(255, 255, 255, 0.42);
            border-radius: 50%;
            transition: all .3s;
            i{
                color: #fff;
                transition: all .3s;
            }
            &:hover{
                background: #fff;
                i{
                    color: var(--color-primary);
                    animation: 0.5s mymove;
                }
            }
        }
    }
    &:hover .action-share-option {
        bottom: 0;
        transform: translateX(-50%) rotateX(0deg);
    }
}