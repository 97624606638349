
// binngin details

.rts-billing-details-area{
    .title{
        font-size: 30px;
    color: #2C3C28;
    }
    form{
        .single-input{
            margin-bottom: 35px;
            input{
                height: 50px;
                width: 100%;
                border: 1px solid #E8E8E8;
                border-radius: 5px;
                margin-top: 10px;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            textarea{
                height: 180px;
                width: 100%;
                border: 1px solid #E8E8E8;
                border-radius: 5px;
                margin-top: 10px;
                padding: 12px;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
        }
        .half-input-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 35px;
            .single-input{
                flex-basis: 50%;
                margin-bottom: 0;
            }
        }
    }
}



.right-card-sidebar-checkout{
    .cottom-cart-right-area{
        padding: 25px 30px;
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            p{
                font-size: 14px;
                color: #74787C;
                margin-top: 15px;
                line-height: 1.5;
                margin-bottom: 20px;
                &.bold{
                    font-weight: 600;
                    color: #2C3C28;
                }
            }
            li{
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                input{
                    display: none;
                }
                label{
                    font-size: 14px;
                    color: #2C3C28;
                    font-weight: 500;
                }
                input[type=checkbox] ~ label::before, input[type=radio] ~ label::before {
                    content: " ";
                    position: absolute;
                    top: 1 px;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    background-color: #ffffff;
                    border: 1px solid #2C3C28;
                    border-radius: 50%;
                    transition: all 0.3s;
                    border-radius: 50%;
                }
                input[type=radio] ~ label::after {
                    width: 8px;
                    height: 8px;
                    left: 3.2px;
                    background: #2C3C28;
                    border-radius: 50%;
                    border: none;
                    top: 22%;
                }
            }
        }
    }
    .rts-btn.btn-primary{
        max-width: 100%;
        text-align: center;
    }
    .single-category{
        margin-bottom: 14px;
        &.with-more{
            position: relative;
            &::after{
                position: absolute;
                content: '\2b';
                font-family: var(--font-three);
                font-size: 14px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                color: var(--color-heading-1);
                pointer-events: none;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
        input{
            display: none;
            padding: 12px;
        }
        label{
            display: block;
            width: 100%;
            &::before{
                background: transparent;
                border: 1px solid #E2E2E2;
                box-shadow: 0px 1.5px 6px rgba(37, 64, 32, 0.05);
                border-radius: 2px;
            }
        }
    }
}