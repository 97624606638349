
// checkout area start

.title-checkout{
    font-size: 30px;
    line-height: 1.3;
}
.right-card-sidebar-checkout{
    border: 2px solid var(--color-primary);
    border-radius: 6px;
    .top-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 36px 0 26px 0;
        margin: 0 36px;
        border-bottom: 1px solid #E2E2E2;
    }
    .single-shop-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 33px 0;
        margin: 0 36px;
        border-bottom: 1px solid #E2E2E2;
        gap: 20px;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
        }
        .left-area{
            display: flex;
            align-items: center;
            gap: 15px;
        }
        .price{
            color: var(--color-heading-1);
            font-weight: 500;
        }
        .thumbnail{
            display: block;
            overflow: hidden;
            border: 1px solid #E2E2E2;
            border-radius: 4px;
            height: 80px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .title{
            font-size: 16px;
            font-weight: 600;
            max-width: 60%;
            color: var(--color-heading-1);
        }
    }
}



.coupon-input-area-1{
    .inner{
        padding: 20px;
    }
    &.login-form{
        .coupon-input-area{
         flex-direction: column;
         p{
            margin-bottom: 20px;
            margin-top: 0;
            font-weight: 500;
            color: var(--color-heading-1);
         }
            form{
                display: flex;
                align-items: center;
                gap: 20px;
                width: 100%;
                justify-content: space-between;
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                }
                input{
                    flex-basis: 40%;
                    &:focus{
                        border: 1px solid var(--color-primary);
                    }
                }
                button{
                    display: block;
                    flex-basis: 20%;
                }
            }
        }
    }
    .coupon-area {
        margin-bottom: 20px;
    }
    
    .coupon-ask {
        padding: 20px 30px;
        background: var(--color-primary);
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 25px;
        border-radius: 6px;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }
        &.cupon-wrapper-1{
            background: transparent;
            border: 1px solid #E9E9E9;
            span {
                font-size: 16px;
                color: var(--color-heading-1);
                font-weight: 400;
                min-width: max-content;
    
            }
        
            button {
                color: var(--color-heading-1);
                font-weight: 600;
                max-width: max-content;
            }
        }

        span {
            font-size: 16px;
            color: #FFFFFF;
            font-weight: 400;
            min-width: max-content;

        }
    
        button {
            color: #ffffff;
            font-weight: 600;
            max-width: max-content;
        }
    }
    
    .coupon-input-area {
        max-height: 0;
        overflow: hidden;
        transition: max-height 300ms;
        display: flex;
        justify-content: space-between;
        transition: .3s;
        &.cupon1{
            .inner{
                width: 100%;
                p{
                    margin-bottom: 20px;
                    margin-top: 0;
                    font-weight: 500;
                    color: var(--color-heading-1);
                }
            }
            .inner .form-area{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                }
            }
        }
        .inner{
            padding: 20px;
        }
        input {
            width: 80%;
            padding: 0 30px;
            height: 50px;
            border-radius: 6px;
            border: 1px solid var(--color-primary);
            outline: none;
            transition: box-shadow 300ms;
            font-size: 16px;
            color: #5c5c5c;
            @media #{$large-mobile} {
                min-height: 50px;
                width: 100%;
            }
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
    
        .apply-btn {
            width: 18%;
            height: 50px;
            background: #f1f1f1;
            color: #e3e3e3;
            border-radius: 6px;
    
            &:hover {
                background: var(--color-primary);
            }
        }
    
        &.show {
            background: #f1f1f159;
            max-height: 150px;
            border-radius: 5px;
            @media #{$large-mobile} {
                max-height: max-content;
            }
        }
    }
    
}