

// filetr style here

.single-filter-box{
    border-radius: 8px;
    border: 1px solid #E2E2E2;
    margin-bottom: 20px;
    &:last-child{
        margin-bottom: 0;
    }
    .title{
        font-size: 20px;
        padding: 30px 30px 20px 30px;
        border-bottom: 1px solid #E2E2E2;
        margin-bottom: 0;
    }
    .filterbox-body{
        padding: 30px;
        @media #{$large-mobile} {
            padding: 15px;
        }
        .price-input-area{
            .half-input-wrapper{
                display: flex;
                align-items: center;
                gap: 40px;
                .single{
                    label{
                        margin-bottom: 7px;
                    }
                    input{
                        border: 1px solid #E2E2E2;
                        height: 35px;
                        border-radius: 4px;
                        color: var(--color-heading-1);
                        font-weight: 500;
                    }
                }
            }
            .range{
                padding: 0;
                border: 1px solid transparent;
                &::-webkit-slider-runnable-track {
                    background-color: var(--color-primary);
                    height: 6px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                }
                &::-ms-track {
                    color: transparent;
                    border: none;
                    background: none;
                    height: 6px;
                }
            }
            .range::-webkit-slider-thumb {
                -webkit-appearance: none !important;
                border-radius: 100%;
                background-color: rgb(255, 255, 255);
                height: 10px;
                width: 10px;
                margin-top: -7px;
                border: 1px solid transparent;
            }
            .range::-webkit-slider-thumb {
                background-color: #aaa;
                box-shadow: 0 0 5px rgba(7, 156, 32, 0.5);
                height: 10px;
                width: 10px;
                border: 1px solid transparent;
            }
            .range:active::-webkit-slider-thumb {
                outline: none;
            }
            .filter-value-min-max{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        .category-wrapper{
            .single-category{
                margin-bottom: 14px;
                &.with-more{
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '\2b';
                        font-family: var(--font-three);
                        font-size: 14px;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: var(--color-heading-1);
                        pointer-events: none;
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
                input{
                    display: none;
                }
                label{
                    display: block;
                    width: 100%;
                    &::before{
                        background: transparent;
                        border: 1px solid #E2E2E2;
                        box-shadow: 0px 1.5px 6px rgba(37, 64, 32, 0.05);
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}

.shop-grid-top{
    .filter-select-area{
        background: #F3F4F6;
        border-radius: 6px;
        margin-left: -0;
        @media #{$laptop-device} {
            margin-left: 0
        }
        @media #{$smlg-device} {
            margin-left: 0;
        }
    }
    .product-area-wrapper-shopgrid-list{
        margin-left: 0;
    }
}

.shop-liust-top{
    .filter-select-area {
        margin-left: 0;
    }
    .product-area-wrapper-shopgrid-list {
        margin-left: 0;
    }
}

.filter-select-area{
    background: #F3F4F6;
    border-radius: 6px;
    margin-left: -30px;
    @media #{$laptop-device} {
        margin-left: 0
    }
    @media #{$smlg-device} {
        margin-left: 0;
    }
    .top-filter{
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }
        .right-end{
            display: flex;
            align-items: center;
            gap: 20px;
            .button-tab-area{
                display: flex;
                align-items: center;
                gap: 10px;
                ul{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin: 0;
                    padding: 0;
                    border: none;
                }
                .single-button{
                    height: 30px;
                    width: 30px;
                    border-radius: 4px;
                    border: 1px solid #2C3C28;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: .3s;
                    padding: 0 !important; 
                    &.active{
                        background: var(--color-primary) !important;
                        border: 1px solid var(--color-primary);
                        svg{
                            rect{
                                stroke: #fff;
                            }
                        }
                    }
                    svg{
                        rect{
                            transition: .3s;
                        }
                    }
                    &:hover{
                        background: var(--color-primary);
                        border: 1px solid var(--color-primary);
                        rect{
                            stroke: #fff;
                        }
                    }
                }
            }
        }
    }
}

.nice-select-area-wrapper-and-button{
    display: flex;
    background: #F3F4F6;
    align-items: center;
    padding: 16px 15px;
    border-radius: 0 0 6px 6px;
    gap: 10px;
    justify-content: space-between;
    border-top: 1px solid #D7DDE8;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
    .nice-select-wrapper-1{
        display: flex;
        align-items: center;
        gap: 10px;
        @media #{$smlg-device} {
            flex-wrap: wrap;
        }
        .single-select{
            width: 230px;
            @media #{$laptop-device} {
                width: 160px;
            }
            .nice-select{
                width: 230px;
                height: 35px;
                display: flex;
                align-items: center;
                font-weight: 600;
                color: #2C3C28;
                @media #{$laptop-device} {
                    width: 160px;
                }
            }
        }
    }
    .button-area{
        display: flex;
        align-items: center;
        gap: 10px;
        height: 35px;
        button{
            height: 35px;
            border: 1px solid  rgba(43, 66, 38, 0.12);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 15px;
            background: #ffff;
            border-radius: 4px;
            color: #2C3C28;
            min-width: max-content;
            @media #{$sm-layout} {
                min-width: max-content;
            }
            &:hover{
                background: var(--color-primary) !important;
                border: 1px solid var(--color-primary);
                color: #fff;
            }
        }
    }

}


.product-area-wrapper-shopgrid-list{
    margin-left: -30px;
    @media #{$laptop-device} {
        margin-left: 0;
    }
    @media #{$smlg-device} {
        margin-left: 0;
    }
    .single-shopping-card-one .cart-counter-action .rts-btn {
        font-size: 14px;
        padding: 6px 4px !important;
        background: transparent;
        color: #629D23;
        border: 1px solid #629D23;
    }
}

.category-active-menu-sidebar{
    padding-left: 0;
    list-style: none;
    ul{
        list-style: none;
        li{
            margin: 0;
            padding-top: 10px;
            ul{
                li{
                    padding: 10px 0;
                }
            }
        }
    }
}
.menu-item[aria-expanded="true"] i{
    &::before{
        content: '\f068' !important;
    }
}

[aria-expanded="true"]{

    .single-category.with-more{
        &::after{
            content: '\f068' !important;
        }
    }
}
.product-area-wrapper-shopgrid-list.with-list{
    .single-shopping-card-one.discount-offer {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        background: #FFFFFF;
        margin-bottom: 15px;
        border: 1px solid #f1f1f1;
        background: #F3F4F6;
    }
    .single-shopping-card-one.discount-offer{
        align-items: center;
    }
    .body-content{
        background: #F3F4F6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media #{$large-mobile} {
            padding: 15px;
        }
        @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
        }
        .title-area-left {
            flex-basis: 53%;

        }
        .natural-value{
            .title{
                color: #74787C;
                margin-bottom: 0;
            }
            .single{
                margin: 7px 0;
                display: flex;
                align-items: center;
                gap: 15px;
                justify-content: space-between;
            }
        }
    }
}


.shop-grid-sidebar-area{
    .sidebar-filter-main{
        @media #{$smlg-device} {
            padding-right: 0;
            gap: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &> div{
                flex-basis: 48%;
                &:last-child{
                    margin-bottom: 20px;
                }
            }
        }
        @media #{$md-layout} {
            padding-right: 0;
            gap: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &> div{
                flex-basis: 48%;
                &:last-child{
                    margin-bottom: 20px;
                }
            }
        }
        @media #{$large-mobile} {
            padding-right: 0;
            gap: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &> div{
                flex-basis: 100%;
                &:last-child{
                    margin-bottom: 20px;
                }
            }
        }
    }
}