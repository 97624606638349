
// about areas styles

.about-banner-area-bg{
    background-image: url(../images/about/01.jpg);
    height: 582px;
    display: flex;
    align-items: center;
}


.inner-content-about-area{
    max-width: 60%;
    margin: auto;
    text-align: center;
    margin-top: -50px;
    @media #{$md-layout} {
        max-width: 95%;
    }
    @media #{$sm-layout} {
        max-width: 100%;
    }
    .title{
        color: #fff;
        font-size: 48px;
        font-weight: 600;
        @media #{$sm-layout} {
            line-height: 1.3;
            font-size: 36px;
        }
    }
    p.disc{
        font-size: 16px;
        color: #fff;
        max-width: 85%;
        margin: auto;
    }
    .rts-btn{
        margin-top: 40px !important;
        margin: auto;
    }
}

.container-3{
    max-width: 1320px;
    margin: auto;
    padding: 0 15px;
}

.about-content-area-1{
    .title{
        font-size: 40px;
        margin-bottom: 40px;
        @media #{$smlg-device} {
            font-size: 32px;
        }
        @media #{$sm-layout} {
            font-size: 26px;
            br{
                display: none;
            }
        }
    }
    p.disc{
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 30px;
    }
    .check-main-wrapper{
        .single-check-area{
            padding: 7px 0;
            padding-left: 25px;
            position: relative;
            &::after{
                position: absolute;
                content: '';
                left: 0;
                height: 4px;
                width: 4px;
                background: #2C3C28;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
            }
        }
    }
}
.section-seperator{
    margin: 0;
}

.title-center-area-main{
    text-align: center;
    max-width: 50%;
    margin: auto;
    @media #{$md-layout} {
        max-width: 80%;
    }
    @media #{$sm-layout} {
        max-width: 100%;
    }
    .title{
        font-size: 40px;
    }
}
.title-area-left{
    .title-left{
        font-size: 40px;
        @media #{$large-mobile} {
            font-size: 26px;
        }
    }
}