
.single-blog-area-start{
    .thumbnail{
        display: block;
        overflow: hidden;
        width: 100%;
        border-radius: 8px 8px 0 0;
        img{
            width: 100%;
            transition: all .3s;
            border-radius: 8px 8px 0 0;
            transform: scale(1.01);
        }
    }
    &.style-two{
        padding: 10px;
        border: 1px solid #E2E2E2;
        border-radius: 6px;
        .blog-body{
            padding: 20px;
            border: none;
        }
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.1);
            }
        }
    }
    .blog-body{
        padding: 30px;
        background: #fff;
        border: 1px solid #E2E2E2;
        border-radius: 0 0 6px 6px;
        border-top: none;
        padding: 30px 10px;
        .top-area{
            display: flex;
            align-items: center;
            gap: 30px;
            margin-bottom: 12px;
            @media #{$large-mobile} {
                gap: 15px;
            }
            .single-meta{
                display: flex;
                align-items: center;
                gap: 5px;
                span{
                    font-size: 14px;
                }
            }
        }
        a{
            .title{
                font-size: 18px;
                line-height: 26px;
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        .shop-now-goshop-btn{
            font-weight: 600;
            span{
                font-weight: 600;
            }
            .plus-icon{
                background: rgba(98, 157, 35, 0.15);
                i{
                    color: var(--color-primary);
                }
            }
        }
        .shop-now-goshop-btn:hover .text {
            transition-delay: 0.0s;
            // transform: translateX(-36px);
        }
    }
}

.single-blog-style-card-border{
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #E2E2E2;
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.2);
            }
        }
    }
    .thumbnail{
        overflow: hidden;
        display: block;
        border-radius: 6px;
        img{
            width: 100%;
            transition: .6s;
        }
    }
    .inner-content-body{
        margin-top: 30px;
        .tag-area{
            display: flex;
            align-items: center;
            gap: 20px;
            @media #{$large-mobile} {
                gap: 8px;
            }
            .single{
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
        a.title-main{
            display: block;
            margin-top: 15px;
            .title{
                font-size: 18px;
                line-height: 26px;
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        .button-area{
            margin-top: 30px;
        }
    }
}


.blog-sidebar-single-wized{
    padding: 30px;
    border-radius: 6px;
    border: 1px solid #E2E2E2;
    margin-bottom: 30px;
    @media #{$sm-layout} {
        padding: 10px;
    }
    form{
        width: 100%;
        position: relative;
        input{
            height: 55px;
            border-radius: 6px;
            background: #F3F4F6;
            font-weight: 500;
            padding-right: 66px;
        }
        button{
            position: absolute;
            right: 0;
            top: 50%;
            left: auto;
            transform: translateY(-50%);
            height: 55px;
            background: var(--color-primary);
            width: 55px;
            color: #fff;
            margin: 0;
            padding: 0;
            border-radius: 6px;
        }
    }
    &.with-title{
        padding: 0;
        .title{
            padding: 24px 30px;
            border-bottom: 1px solid #E2E2E2;
            margin-bottom: 0;
        }
        .category-main-wrapper{
            padding: 30px;
            .single-category-area{
                height: 45px;
                display: flex;
                align-items: center;
                padding: 18px;
                border: 1px solid #E2E2E2;
                border-radius: 3px;
                cursor: pointer;
                margin-bottom: 5px;
                transition: .3s;
                background: #F3F4F6;
                p{
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--color-heading-1);
                    transition: .3s;
                }
                &:hover{
                    border: 1px solid var(--color-primary);
                    background: var(--color-primary);
                    transform: scale(1.02) translateY(-2px);
                    p{
                        color: #fff;
                    }
                }
            }
        }
        .latest-post-small-area-wrapper{
            padding: 30px;
            @media #{$sm-layout} {
                padding: 10px;
            }
            .single-latest-post-area{
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 15px;
                @media #{$smlg-device} {
                    flex-direction: column;
                    align-items: flex-start;
                }
                @media #{$md-layout} {
                    flex-direction: row;
                    align-items: flex-start;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                .thumbnail{
                    min-width: max-content;
                    overflow: hidden;
                    border-radius: 6px;
                    &:hover{
                        img{
                            transform: scale(1.1);
                        }
                    }
                    img{
                        min-width: max-content;
                        transition: .3s;
                    }
                }
                .icon-top-area{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    span{
                        color: #74787C;
                    }
                }
                .title-sm-blog{
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    transition: .3s;
                    margin-bottom: 0;
                    margin-top: 10px;
                    font-family: var(--font-secondary);
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
        }
        .tags-area-blog-short-main{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 30px;
            gap: 10px;
            @media #{$sm-layout} {
                padding: 10px;
            }
            .single-category{
                max-width: max-content;
                padding: 10px 16px;
                border-radius: 3px;
                border: 1px solid #E2E2E2;
                font-weight: 700;
                color: #2C3C28;
                transition: .3s;
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    transform: scale(1.02) translateY(-3px);
                }
            }
        }
    }
    &.with-add{
        position: relative;
        background-image: url(../images/blog/thumb/12.jpg);
        padding: 80px 40px;
        @media #{$large-mobile} {
            padding: 10px;
        }
        .add-are-content{
            span.pre{
                font-size: 14px;
                color: #fff;
                font-weight: 600;
            }
            .title{
                font-size: 26px;
                line-height: 1.3;
                margin-top: 23px;
                color: #fff;
                margin-bottom: 30px;
                span{
                    font-weight: 400;
                    color: #fff;
                }
            }
        }
        .shop-now-goshop-btn{
            .plus-icon{
                background: rgba(98, 157, 35, 0.15);
            }
            .text{
                color: #fff;
                font-weight: 600;
            }
        }
    }
    .instagram-post-main-wrapper{
        display: flex;
        align-items: center;
        gap: 14px;
        flex-wrap: wrap;
        padding: 30px;
        width: 100%;
        @media #{$sm-layout} {
            padding: 10px;
        }
        a{
            flex-basis: 22.5%;
            img{
                min-width: max-content;
            }
        }
    }
}


.single-blog-main-wrapper-top{
    margin-right: -30px;
    @media #{$md-layout} {
        margin-right: 0;
    }
    @media #{$sm-layout} {
        margin-right: 0;
    }
    .title-main{
        .title{
            padding-bottom: 20px;
            border-bottom: 1px solid #E2E2E2;
        }
    }
    .single-blog-style-card-border{
        &:hover{
            img{
                transform: scale(1.05);
            }
        }
    }

}


.blog-details-area-1{
    margin-right: -30px;
    @media #{$md-layout} {
        margin-right: 0;
    }
    @media #{$sm-layout} {
        margin-right: 0;
    }
    .thumbnail{
        overflow: hidden;
        display: block;
        border-radius: 6px;
        &:hover{
            img{
                transform: scale(1.05);
            }
        }
        img{
            transition: .5s;
        }
    }
}

.blog-details-area-1{
    background-color: #F3F4F6;
    border-radius: 6px;
    .thumbnail{
        overflow: hidden;
    }
    .body-content-blog-details{
        padding: 30px;
        background-color: #F3F4F6;
        border-radius: 6px;
        @media #{$large-mobile} {
            padding: 10px;
        }
        .top-tag-time{
            display: flex;
            align-items: center;
            gap: 17px;
            .single{
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
        h1.title{
            font-size: 30px;
            margin-top: 25px;
            @media #{$large-mobile} {
                font-size: 22px;
            }
        }
        p.disc{
            margin-bottom: 25px !important;
            font-size: 16px;
        }  
        p.quote{
            font-size: 22px;
            color: var(--color-heading-1);
            line-height: 1.3;
            font-weight: 500;
            @media #{$large-mobile} {
                font-size: 20px;
            }
        } 
    }
    .thumbnail-row-iamge{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 40px;
        margin-top: 25px;
        @media #{$md-layout} {
            flex-direction: column;
        }
        @media #{$sm-layout} {
            flex-direction: column;
        }
    }
}
.popular-product-col-7-area.rts-section-gapBottom {
    @media #{$laptop-device} {
        padding: 0 15px;
        padding-bottom: 60px;
    }
}
.tag-social-share-wrapper-area-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$sm-layout} {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
    .tags-area{
        display: flex;
        align-items: center;
        gap: 15px;
        @media #{$small-mobile} {
            flex-wrap: wrap;
        }
        span{
            display: block;
            font-weight: 600;
            color: #2C3C28;
        }
        button{
            height: 35px;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #E2E2E2;
            border-radius: 4px;
            background: #fff;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            color: #2C3C28;
            &:hover{
                background: var(--color-primary);
                border-color: var(--color-primary);
                transform: scale(1.02) translateY(-3px);
                color: #fff;
            }
        }
    }
    .social-icons{
        display: flex;
        align-items: center;
        gap: 30px;
        span{
            font-weight: 600;
            color: #2C3C28;
            text-transform: uppercase;
        }
        ul{
            padding: 0;
            margin: 0;

            display: flex;
            align-items: center;
            gap: 25px;
            list-style: none;
            li{
                a{
                    i{
                        color: #2C3C28;
                         text-transform: uppercase;
                    }
                }
            }
        }
    }
}


.blog-details-author{
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 30px;
    border-radius: 6px;
    background: #fff;
    border: 1px solid #E2E2E2;
    margin-top: 60px;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    .thumbnail{
        min-width: max-content;
        img{
            min-width: max-content;
        }
    }
    p{
        margin-bottom: 20px;
    }
    .social{
        ul{
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            gap: 7px;
            list-style: none;
            li{
                margin: 0;
                padding: 0;
                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    background: #F3F4F6;
                    transition: .3s;
                    i{
                        color: #2C3C28;
                    }
                    &:hover{
                        background: var(--color-primary);
                        transform: scale(1.1) translateY(-2px);
                        i{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}


.comment-replay-area-start{
    margin-top: 39px;
    .title{
        font-size: 30px;
    }
    .single-comment-area{
        display: flex;
        align-items: center;
        gap: 25px;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }
        &.bottom{
            border-top: 1px solid #E2E2E2;
        }
        .thumbanil{
            min-width: max-content;
            img{
                min-width: max-content;
            }
        }
        .comment-information{
            .top-area{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .left{
                    span{
                        color: #74787C;
                    }
                    .title{
                        font-size: 20px;
                        margin-bottom: 0;
                    }
                }
                .replay{
                    color: #629D23;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            p.disc{
                margin: 0;
                margin-bottom: 0 !important;
            }
        }
    }
}