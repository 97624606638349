

// buttons area start

.rts-btn{
    max-width: max-content;
    padding: 14px 25px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    &:focus{
        box-shadow: none !important;
        border: none !important;
    }
    &.btn-primary{
        background: #629D23;
        border-radius: 6px;
        display: block;
        max-width: max-content;
    }
    &.radious-sm{
        border-radius: 6px;
    }
    &.with-icon{
        display: flex;
        align-items: center;
        gap: 10px;
        transition: .3s;
        .arrow-icon{
            display: inline-block;
            transition: opacity 0.4s 0.25s, transform 0.6s 0.25s;
            transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
        }
        .arrow-icon + .arrow-icon {
            margin-inline-end: 0;
            margin-inline-start: 8px;
            display: inline-block;
            margin-inline-start: 0;
            margin-inline-end: 0;
            opacity: 0;
            transform: translateX(-10px);
            transition-delay: 0s;
            order: -2;
            display: none;
        }
        .btn-text{
            display: inline-block;
            transition: transform 0.6s 0.125s cubic-bezier(0.1, 0.75, 0.25, 1);
        }
        &:hover{
            background: #2c3c28;
            .btn-text{
                transition-delay: 0.1s;
                transform: translateX(0);
            }
            .arrow-icon + .arrow-icon{
                opacity: 1;
                transform: translateX(0);
                transition-delay: 0.225s;
            }
            .arrow-icon{
                opacity: 1;
                transition-delay: 0s;
                transform: translateX(0);
            }
        }
    }
}


.shop-now-goshop-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    .plus-icon{
        height: 30px;
        width: 30px;
        background: var(--color-primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            color: #fff;
        }
    }
    span{
        font-weight: 700;
        color: #232722;
    }
}

.shop-now-goshop-btn{
    max-width: max-content;
    transition: .3s;
    .plus-icon{
        opacity: 0;
        display: flex;
        transition: .3s;
        // transition: opacity 0.4s 0.25s, transform 0.6s 0.25s;
        // transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
    }
    .plus-icon + .plus-icon {
        margin-inline-end: 0;
        margin-inline-start: 8px;
        display: flex;
        margin-inline-start: 0;
        margin-inline-end: 0;
        opacity: 1;
        transform: translateX(-0px);
        transition-delay: 0s;
        order: -2;
        transition: .3s;
    }
    .text{
        display: flex;
        transition: transform 0.6s 0.125s cubic-bezier(0.1, 0.75, 0.25, 1);
        margin-inline-start: -1px;
        transition: .3s;
    }
    &:hover{
        .text{
            transition-delay: 0.0s;
            // transform: translateX(-33px);
        }
        .plus-icon + .plus-icon{
            opacity: 1;
            // transform: translateX(-30px);
            transition-delay: 0.0s;
            background: var(--color-heading-1);
        }
        .plus-icon{
            opacity: 0;
            transition-delay: 0s;
            // transform: translateX(-30px);
        }
    }
}


.btn-border-only{
    border-radius: 6px;
    border: 1px solid #E2E2E2;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 26px;
    gap: 10px;
    font-weight: 500;
    color: #2C3C28;
    transition: .3s;
    box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.06);
    @media #{$sm-layout} {
        height: 40px;
        padding: 0;
        width: 40px;
    }
    @media #{$large-mobile} {
        border: none;
    }
    &:hover{
        background: var(--color-primary);
        color: #fff;
    }
    &.wishlist{
        @media #{$sm-layout} {
            display: none;
        }
    }
    &.account{
        @media #{$sm-layout} {
            display: none;
        }
    }
}