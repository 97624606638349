
.outline-light-bg-wrapper{
    background-color: #F5F6F7;
    padding: 30px;
    border-radius: 6px;
}


.bg_gradient-tranding-items{
    background: linear-gradient(180deg, #F3F4F6 0%, rgba(255, 255, 255, 0) 76.69%);
}



.tranding-items-wrapper-padding-bg-wrapper-list-tabs{
    padding: 0px 30px 30px;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    @media #{$large-mobile} {
        padding: 0 15px 15px;
    }
    @media #{$small-mobile} {
        padding: 15px 0;
    }
    button{
        padding: 12px 20px;
        width: 100%;
        display: block;
        text-align: left;
        color: #74787C;
        border-bottom: 1px solid #E2E2E2 !important;
        border-radius: 0 !important;
        padding-left: 0;
        padding-right: 0;
        &:hover{
            background-color: transparent !important;
            border-bottom: 1px solid var(--color-primary) !important;
            color: var(--color-primary) !important;
        }
        &.active{
            background-color: transparent !important;
            border-bottom: 1px solid var(--color-primary) !important;
            color: var(--color-primary) !important;
        }
    }
    .main-nav{
        width: 100%;
    }
}

.add-in-tranding-items-area{
    position: relative;
    .content-area{
        position: absolute;
        left: 40px;
        top: 40px;
        .title{
            line-height: 1.3;
            font-weight: 600;
        }
    }
}
.add-in-tranding-items-area img {
    width: auto;
    max-height: 421px;
    @media #{$smlg-device} {
        width: 100%;
        object-fit: cover;
    }
}

.margin-decrease-y{
    margin: 0;
}
.margin-decrease-y{
    margin: 0;
}
