// any where in this home
#anywhere-home.bgshow {
    background: #0e1013;
    opacity: 70%;
    visibility: visible;
    pointer-events: visible;
    z-index: 999;
    top: 0;
}
#anywhere-home {
    cursor: url(../images/banner/shape/close.png), auto;
    background: #0e1013;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease-in-out;
    pointer-events: none;
    z-index: 50;
}

.index-bg-gray{
    background: #F3F4F6;
    .cover-card-main-over{
        background: #fff;
        border-radius: 6px;
        .single-shopping-card-one{
            border: 1px solid #E2E2E2;
            .thumbnail-preview{
                border: none;
            }
        }
        .single-category-one{
            position: relative;
            z-index: 1;
            text-align: center;
            &::after{
                position: absolute;
                content: '';
                left: 5px;
                right: 5px;
                top: 5px;
                bottom: 5px;
                width: 93%;
                height: 140px;

                background: linear-gradient(180deg, #F3F4F6 0%, #FFFFFF 100%);
                border-radius: 6px;
                z-index: -1;
                @media #{$small-mobile} {
                    width: 97%;
                }
            }
        }
    }
}




.container{
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 1696px;
    margin: auto;
    padding: 0 15px;
}
.container-2{
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
    max-width: 1350px;
    margin: auto;
}

.countdown{
    .countDown{
        display: flex;
        align-items: center;
        gap: 15px;
        @media #{$small-mobile} {
            flex-wrap: wrap;
        }
        .container{
            position: relative;
            padding: 3px 43px 3px 7px;
            &::after{
                color: rgba(255, 255, 255, 0.897);
            }
            &:first-child{
                &::after{
                    position: absolute;
                    content: "Days";
                    right: 5px;
                    top: -1px;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &:nth-child(2){
                &::after{
                    position: absolute;
                    content: 'Hour';
                    right: 5px;
                    top: -1px;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &:nth-child(3){
                &::after{
                    position: absolute;
                    content: 'Min';
                    right: 5px;
                    top: -1px;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &:nth-child(4){
                &::after{
                    position: absolute;
                    content: 'Sec';
                    right: 5px;
                    top: -1px;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .a{
            font-weight: 500;
            color: #fff;
        }
    }
}


.bg_light-1{
    background: #F3F4F6 !important;
}


.titlw-area-between-best-seller-anim{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
        padding-left: 55px;
        position: relative;
        &::after{
            position: absolute;
            height: 30px;
            width: 30px;
            background: #EABC5E;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
}

.next-prev-swiper-wrapper.d-sm-none{
    display: block !important;
    @media #{$large-mobile} {
        display: none;
    }
}
.bg_primary{
    background: var(--color-primary) !important;
}

@media (min-width: 992px) {
    .col-lg-20 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }
}


.rts-product-details-section2 {
    padding-bottom: 0;
    .product-thumb-area {
        display: flex;
        flex-direction: column;

        .product-thumb {
            margin-bottom: 30px;
        }

        .product-thumb-filter-group {
            flex-direction: row;

            .thumb-filter {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }
    }
    .contents{
        table{
            width: 100%;
        }
    }

    @media(max-width: 1200px) {
        .details-product-area {
            flex-direction: column;

            .product-thumb-area {
                width: 100%;
                margin: 0;
                margin-bottom: 20px;
                flex-direction: column;

                .thumb-wrapper {
                    width: 100%;
                }

                .product-thumb {
                    margin-bottom: 20px;
                }

                .product-thumb-filter-group {
                    margin-left: 0;
                    flex-direction: row;

                    .thumb-filter {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
